'use strict';

var $ = window.jQuery;

$(function () {
  $('.validate').validate({
    errorClass: 'error input-error',
    errorElement: 'span'
  });
});

$(function () {
  var $body = $('body');

  var getMessage = function getMessage(identifier) {
    if (!$body.attr('data-msg-' + identifier)) {
      console.error('Missing translation-data on body-element: data-msg-' + identifier + '="\xB7\xB7\xB7"');
    }

    return $body.data('msg-' + identifier);
  };

  $.extend($.validator.messages, {
    required: getMessage('required'),
    email: getMessage('email')
  });
});

$(function () {
  var $forms = $('form[data-form-action]');

  $forms.each(function (i, form) {
    var $form = $(form);

    $form.on('submit', function () {
      if ($form.hasClass('validate') && !$form.valid()) {
        return; // Only submit valid forms
      }

      var $formElement = $form.find('.form');
      var $workIndicatorElement = $form.find('.work-indicator');
      var $successElement = $form.find('.success');

      $formElement.hide();
      $workIndicatorElement.show();

      var formData = $form.serialize();

      $.ajax({
        type: 'POST',
        url: $form.data('form-action'),
        data: formData,
        success: function success() {
          var redirectUrl = $form.data('form-success-redirect');
          if (!(redirectUrl == null)) {
            window.location = redirectUrl;
          } else {
            $workIndicatorElement.hide();
            $successElement.show();
          }
        }
      }).fail(function () {
        // This should never happen
        $workIndicatorElement.hide();
        $formElement.show();
        window.alert('Error - please try again');
      });

      // Prevent default behaviour
      return false;
    });
  });
});
'use strict';

window.FontAwesomeConfig = {
  searchPseudoElements: true
};

$(function () {

  /*##################
  Sticky footer
  ##################*/

  var oldHeight = -1;

  var onResize = function onResize() {
    var footer = $('footer.footer');
    var footerHeight = footer.innerHeight();
    if (oldHeight === footerHeight) {
      return;
    } // skip if the height has not changed since last time

    $('.sticky-footer-page-content-wrapper').css({ marginBottom: +(footerHeight * -1) + 'px' });
    $('#sticky-footer-styling').remove();
    $('<style id=\'sticky-footer-styling\'>.sticky-footer-page-content-wrapper::after{height:' + footerHeight + 'px !important} </style>').appendTo('head');
  };

  $(window).resize(onResize);

  onResize();

  /*##################
  Mobile menu
  ##################*/

  var burgerMenu = $('.header-burger');

  burgerMenu.click(function (event) {
    var mobilemenu = $('.mobilemenu');
    var burger = $('.header-burger');

    burger.toggleClass('header-burger--active');
    mobilemenu.toggleClass('mobilemenu--active');

    return false;
  });
});