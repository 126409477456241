
window.FontAwesomeConfig = {
    searchPseudoElements: true
}

$(function () {

  /*##################
  Sticky footer
  ##################*/

  var oldHeight = -1

  var onResize = function () {
    var footer = $('footer.footer')
    var footerHeight = footer.innerHeight()
    if (oldHeight === footerHeight) {
      return
    } // skip if the height has not changed since last time

    $('.sticky-footer-page-content-wrapper').css({ marginBottom: +(footerHeight * -1) + 'px' })
    $('#sticky-footer-styling').remove()
    $('<style id=\'sticky-footer-styling\'>.sticky-footer-page-content-wrapper::after{height:' + footerHeight + 'px !important} </style>').appendTo('head')
  }

  $(window).resize(onResize)

  onResize()


  /*##################
  Mobile menu
  ##################*/

  var burgerMenu = $('.header-burger')

  burgerMenu.click(function (event) {
    var mobilemenu = $('.mobilemenu')
    var burger = $('.header-burger')

    burger.toggleClass('header-burger--active')
    mobilemenu.toggleClass('mobilemenu--active')

    return false
  })

})
